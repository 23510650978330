import React, { useCallback, useEffect, useState } from 'react'
import { Add } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography
} from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { DataGridPro, GridRowEditStopReasons, GridRowModes, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import { formatNumbers } from '../../Report/components/dataProcess/DataProcess'
import AllocationActionCell from './AllocationActionCell'
import UploadPortfolioPopup from './UploadPortfolioPopup'
import ViewPortfolioPopup from './ViewPortfolioPopup'

const UmaTransitionPortfolioTab = (props) => {
  const {
    setData, transitionPortfolioAllocationData, setTransitionPortfolioAllocationData, setTransitionUploadPortfolioData, transitionUploadPortfolioData, setAllStepsVisited, setTaxlotInformation, umaDetails
  } = props
  const apiRef = useGridApiRef()
  const { showError } = useErrorToast()
  const [rowModesModel, setRowModesModel] = useState({})
  const [viewPortfolioPopup, setViewPortfolioPopup] = useState(false)
  const [uploadPortfolioPopup, setUploadPortfolioPopup] = useState(false)
  const [currentPortfolioId, setCurrentPortfolioId] = useState(null)
  const [rowErrors, setRowErrors] = useState({})
  const [currentAllocationGridState, setCurrentAllocationGridState] = useState(null)

  // Function to save the current state of the DataGridPro
  const handleCurrentAllocationTableStateChange = () => {
    if (apiRef?.current?.exportState) {
      const state = apiRef?.current?.exportState()
      setCurrentAllocationGridState(state)
    }
  }

  // Effect to restore the grid state whenever currentAllocationGridState changes
  useEffect(() => {
    if (apiRef?.current?.restoreState && currentAllocationGridState) {
      const resetGridStateObj = {
        columns: currentAllocationGridState?.columns || {},
        pinnedColumns: currentAllocationGridState?.pinnedColumns || {},
        sorting: currentAllocationGridState?.sorting || []
      }
      apiRef?.current?.restoreState(resetGridStateObj)
    }
  }, [currentAllocationGridState, viewPortfolioPopup, rowModesModel, uploadPortfolioPopup, currentPortfolioId, rowErrors, transitionPortfolioAllocationData, transitionUploadPortfolioData, umaDetails, setTaxlotInformation])

  const missingPortfolioError = (transitionPortfolioAllocationData, transitionUploadPortfolioData) => {
    if (transitionPortfolioAllocationData && transitionPortfolioAllocationData.length) {
      if (!Object.keys(transitionUploadPortfolioData).length) return true
      else if (Object.keys(transitionUploadPortfolioData).length !== transitionPortfolioAllocationData.length) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  const hasMissingPortfolioError = useCallback(
    () => missingPortfolioError(transitionPortfolioAllocationData, transitionUploadPortfolioData),
    [transitionPortfolioAllocationData, transitionUploadPortfolioData]
  )

  useEffect(() => {
    // On screen load, filter out incomplete rows
    return () => {
      setTransitionPortfolioAllocationData((prevRows) =>
        prevRows.filter((row) => row.account)
      )
    }
  }, [])

  // add error key in allocations if below conditions matches
  useEffect(() => {
    if (!transitionPortfolioAllocationData) return

    const updatedOnboardingAllocation = transitionPortfolioAllocationData?.map((allocation) => {
      const rowError = rowErrors[allocation.id] || {}
      return {
        ...allocation,
        error:
          rowError.accountError ||
          hasMissingPortfolioError()
      }
    })

    if (updatedOnboardingAllocation?.some((data) => data?.error) || !updatedOnboardingAllocation?.length) {
      setAllStepsVisited(false)
    }

    setData(updatedOnboardingAllocation)
  }, [rowErrors, transitionPortfolioAllocationData, hasMissingPortfolioError])

  // store portfolio data for allocation
  const updatePortfolioDataForRow = (rowId, data, fundingInformation) => {
    setTransitionUploadPortfolioData((prev) => {
      const existingData = Array.isArray(prev[rowId]?.data) ? prev[rowId].data : []
      const updatedData = Array.isArray(data) ? [...existingData, ...data] : [...existingData, data]
      return {
        ...prev,
        [rowId]: {
          ...prev[rowId],
          data: updatedData,
          fundingInformation: {
            ...(prev[rowId]?.fundingInformation || {}),
            ...fundingInformation
          }
        }
      }
    })
  }

  const transitionPortfolioAllocationColumns = [
    {
      field: 'account',
      headerName: 'Account Name',
      editable: true,
      flex: 3,
      preProcessEditCellProps: (params) => {
        const hasError = !params?.props?.value
        setRowErrors((prevErrors) => ({
          ...prevErrors,
          [params.id]: {
            ...prevErrors[params.id],
            accountError: hasError
          }
        }))
        return { ...params.props, error: hasError }
      },
      renderEditCell: (params) => (
        <TextField
          variant='outlined'
          value={params.value || ''}
          sx={{
            width: '100%',
            height: '40px'
          }}
          error={rowErrors[params.id]?.accountError}
          InputProps={{
            sx: {
              height: '100%'
            }
          }}
          onChange={(event) => {
            const newValue = event.target.value
            params.api.setEditCellValue({
              id: params.id,
              field: 'account',
              value: newValue
            })
          }}
          onKeyDown={(event) => {
            const newValue = event.target.value
            if (event.key === 'Enter') {
              event.preventDefault()
              if (newValue?.length >= 3) {
                handleSaveClick(params.id)
              } else {
                showError('Please enter at least 3 characters.')
              }
            }
          }}
        />
      )
    },
    {
      field: 'marketValue',
      headerName: 'Market Value',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      valueGetter: (params) => {
        const data = transitionUploadPortfolioData?.[params?.row?.id]?.fundingInformation
        if (!data) return null
        const marketValue = data.fundingSource === 'CASH'
          ? data?.totalCashValue
          : data?.totalPortfolioMarketValue
        return (marketValue !== null && marketValue !== undefined)
          ? parseFloat(parseFloat(marketValue).toFixed(2))
          : null
      },
      renderCell: (params) => {
        const value = params?.value
        if (value !== null && value !== undefined) {
          const formattedValue = formatNumbers(Math.abs(value), 2, '$')
          return value < 0 ? `-${formattedValue}` : formattedValue
        }
        return 'N/A'
      }
    },
    {
      field: 'totalCash',
      headerName: 'Total Cash',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      valueGetter: (params) => {
        const data = transitionUploadPortfolioData?.[params?.row?.id]?.fundingInformation
        if (!data) return null
        const totalCashValue = data?.totalCashValue
        if (totalCashValue === '') return null
        return totalCashValue !== undefined && totalCashValue !== null
          ? parseFloat(parseFloat(totalCashValue).toFixed(2))
          : null
      },
      renderCell: (params) => {
        const value = params?.value
        if (value !== null && value !== undefined) {
          const formattedValue = formatNumbers(Math.abs(value), 2, '$')
          return value < 0 ? `-${formattedValue}` : formattedValue
        }
        return 'N/A'
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 200,
      cellClassName: 'actions',
      renderCell: (params) => {
        const rowError = rowErrors[params.id] || {}
        return (
          <AllocationActionCell
            params={params}
            rowModesModel={rowModesModel}
            apiRef={apiRef}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            hasStrategyError={rowError.accountError}
            hasPortfolioData={transitionUploadPortfolioData}
            setTransitionUploadPortfolioData={setTransitionUploadPortfolioData}
            setCurrentPortfolioId={setCurrentPortfolioId}
            setViewPortfolioPopup={setViewPortfolioPopup}
          />
        )
      }
    },
    {
      field: 'upload',
      type: 'actions',
      width: 180,
      sortable: false,
      align: 'center',
      cellClassName: 'actions',
      renderCell: (params) => {
        const currentRowValues = apiRef?.current?.getRowWithUpdatedValues(params?.id) || {}
        const fundingInformation = transitionUploadPortfolioData?.[params.row.id]?.fundingInformation || {}
        const dataAvailable = transitionUploadPortfolioData?.[params.row.id]?.data?.length > 0
        const totalCashValue = fundingInformation?.totalCashValue
        const accountValue = currentRowValues?.account || params?.row?.account
        // Show tick icon if (funding source is not 'CASH' and data is available) OR (funding source is 'CASH' and cash value is present)
        const showTickIcon = (
          (fundingInformation.fundingSource !== 'CASH' && dataAvailable) ||
          (fundingInformation.fundingSource === 'CASH' && totalCashValue)
        )
        return showTickIcon
          ? <CheckCircleIcon color='success' />
          : (
            <Button
              variant='contained'
              disabled={
              !accountValue || accountValue?.length < 3 || // Disable if account name is less than 3 characters
              (Object.keys(transitionUploadPortfolioData)?.length &&
                (transitionUploadPortfolioData[params.row.id]?.fundingInformation?.fundingSource === 'CASH' ||
                  transitionUploadPortfolioData[params.row.id]?.data?.length))
            }
              onClick={(e) => {
                e.preventDefault()
                setUploadPortfolioPopup(true)
                setCurrentPortfolioId(params.row.id)
              }}
            >
              Upload
            </Button>
            )
      }
    }
  ]

  const handleRowEditStop = (params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.escapeKeyDown ||
      params.reason === GridRowEditStopReasons.enterKeyDown ||
      params.reason === GridRowEditStopReasons.tabKeyDown
    ) {
      event.defaultMuiPrevented = true
    }

    event.defaultMuiPrevented = true
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    // Update the isInEditMode field to true for the edited row
    setTransitionPortfolioAllocationData((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, isInEditMode: true } : row
      )
    )
  }

  const handleSaveClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    // Update the isInEditMode field to false when saving the row
    setTransitionPortfolioAllocationData((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, isInEditMode: false } : row
      )
    )
  }

  const handleDeleteClick = (id) => {
    setTransitionPortfolioAllocationData((prevRows) =>
      prevRows.filter((row) => row.id !== id)
    )

    setTransitionUploadPortfolioData((prevData) => {
      const updatedData = { ...prevData }
      delete updatedData[id]
      return updatedData
    })
  }

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = transitionPortfolioAllocationData?.find((row) => row.id === id)

    if (editedRow?.isNew) {
      setTransitionPortfolioAllocationData((prevRows) =>
        prevRows.filter((row) => row.id !== id)
      )
    } else {
      // Set isInEditMode to false if row exists
      setTransitionPortfolioAllocationData((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, isInEditMode: false } : row
        )
      )
    }

    setRowErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        accountError: false
      }
    }))
  }

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false, isInEditMode: false } // Make sure isInEditMode is false
    setTransitionPortfolioAllocationData((prevRows) =>
      prevRows.map((row) =>
        row.id === newRow.id ? updatedRow : row
      )
    )
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const closeModal = () => {
    setUploadPortfolioPopup(false)
  }

  const closeShowPortfolioPopup = () => {
    setViewPortfolioPopup(false)
  }

  const EditToolbar = (props) => {
    const { setTransitionPortfolioAllocationData, setRowModesModel } = props

    const handleClick = () => {
      const id = randomId()
      setTransitionPortfolioAllocationData((oldRows) => [...oldRows, { id, account: '', isNew: true, isInEditMode: true }])
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'account' }
      }))
    }

    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          color='primary' startIcon={<Add />} onClick={handleClick}
        >
          Add Account
        </Button>
      </GridToolbarContainer>
    )
  }

  return (
    <>
      {viewPortfolioPopup && (
        <ViewPortfolioPopup
          transitionUploadPortfolioData={(transitionUploadPortfolioData[currentPortfolioId] || {})}
          open={viewPortfolioPopup}
          closeModal={closeShowPortfolioPopup}
        />
      )}
      {uploadPortfolioPopup && (
        <UploadPortfolioPopup
          open={uploadPortfolioPopup}
          umaDetails={umaDetails}
          closeModal={closeModal}
          setTaxlotInformation={setTaxlotInformation}
          setTransitionUploadPortfolioData={(data, fundingInformation) => updatePortfolioDataForRow(currentPortfolioId, data, fundingInformation)}
        />
      )}
      <Box sx={{ position: 'relative' }}>
        <Typography sx={{ marginLeft: '5px', marginBottom: '10px', fontWeight: 600, color: '#74788D', fontSize: '17px', fontFamily: 'Open Sans' }}>Create Current Allocation Model</Typography>
        {
          missingPortfolioError(transitionPortfolioAllocationData, transitionUploadPortfolioData) && (
            <Alert
              severity='warning'
              sx={{
                position: 'absolute',
                marginBottom: '10px',
                marginTop: '8px',
                top: '-12px',
                padding: '1px 8px',
                right: '0px',
                zIndex: 98,
                '& .MuiAlert-icon': {
                  padding: '3px 0'
                },
                '& .MuiAlert-message': {
                  padding: '3px 0'
                }
              }}
            >Please ensure all accounts have an associated portfolio uploaded.
            </Alert>
          )
        }
        <Divider sx={{ marginBottom: '20px' }} />
        <Card variant='outlined' sx={{ marginBottom: 2 }}>
          <CardContent>
            <DataGridPro
              autoHeight
              rows={transitionPortfolioAllocationData}
              columns={transitionPortfolioAllocationColumns}
              disableRowSelectionOnClick
              getRowId={(row) => row?.id}
              rowModesModel={rowModesModel}
              hideFooter
              sx={{
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none !important'
                },
                '& .MuiDataGrid-cell--editing': {
                  outline: 'none !important'
                }
              }}
              apiRef={apiRef}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              editMode='row'
              processRowUpdate={processRowUpdate}
              onColumnWidthChange={() => handleCurrentAllocationTableStateChange()}
              onColumnOrderChange={() => handleCurrentAllocationTableStateChange()}
              onSortModelChange={() => handleCurrentAllocationTableStateChange()}
              onCellEditStart={handleCellEditStart}
              onCellEditStop={handleCellEditStop}
              slots={{
                toolbar: EditToolbar
              }}
              slotProps={{
                toolbar: { setTransitionPortfolioAllocationData, setRowModesModel }
              }}
              initialState={{
                ...currentAllocationGridState?.initialState,
                pagination: { paginationModel: { pageSize: 5 } }
              }}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

export default UmaTransitionPortfolioTab
