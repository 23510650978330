import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { CSVLink } from 'react-csv'
import { ErrorBoundary } from 'react-error-boundary'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Tab } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import * as Sentry from '@sentry/react'
import AccountTitle from '../../../components/AccountTitle'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { useErrorToast } from '../../../hooks/useErrorToast'
import ErrorFallback from '../../ErrorFallback'
import CurrentImport from './CurrentImport'
import UploadForm from './UploadForm'
import '../import-portfolio.scss'

const taxlotCsvHeaders = [
  { label: 'Name', key: 'instrName' },
  { label: 'Symbol', key: 'instrId' },
  { label: 'Purchase Date', key: 'purchaseDate' },
  { label: 'Total Purchase Cost', key: 'purchaseCost' },
  { label: 'Quantity / Shares', key: 'shares' },
  { label: 'ISO Country Code', key: 'isoCountryCode' }
]

const companyCsvHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Total Purchase Cost', key: 'purchaseCost' },
  { label: 'Quantity / Shares', key: 'shares' }
]

// headers for Download Portfolio In Import Format
const importHeaders = [
  { label: 'Symbol', key: 'localSymbol' },
  { label: 'Purchase Date', key: 'purchaseDate' },
  { label: 'Total Purchase Cost', key: 'purchaseCost' },
  { label: 'Quantity / Shares', key: 'shares' },
  { label: 'ISO Country Code', key: 'isoCountryCode' }
]

const AccountPortfolioImport = () => {
  const { showError } = useErrorToast()
  const { user, checkAccess } = useAuth()
  const params = useParams()
  const [originalTaxlot, setOriginalTaxlot] = useState([])
  const [companyWiseTaxlot, setCompanyWiseTaxlot] = useState([])
  const [taxLotDataDownloadFormat, setTaxLotDataDownloadFormat] = useState([])
  const [companyDataDownloadFormat, setCompanyDataDownloadFormat] = useState([])
  const [noDataMsg, setNoDataMsg] = useState('')
  const [isTaxlotLoading, setIsTaxlotLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState('Current Portfolio')
  const [portfolioLevel, setPortfolioLevel] = useState('Taxlot')

  const dataSecure = localStorage.getItem('object')
  const getStoreData = dataSecure ? JSON.parse(dataSecure) : []
  const filterSecureData = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  // CSV config for Download Portfolio In Displayed taxlot Format
  const displayedTaxlotCsvConfig = {
    filename: 'Export-Current-Portfolio.csv',
    headers: taxlotCsvHeaders,
    data: taxLotDataDownloadFormat || originalTaxlot || []
  }

  // CSV config for Download Portfolio at company level
  const displayedCompanyCsvConfig = {
    filename: 'Export-Current-Portfolio.csv',
    headers: companyCsvHeaders,
    data: companyDataDownloadFormat || companyWiseTaxlot || []
  }

  // CSV config for Download Portfolio In Import Format
  const importFormatCsvConfig = {
    filename: 'Import-Current-Portfolio-format.csv',
    headers: importHeaders,
    data: taxLotDataDownloadFormat || originalTaxlot || []
  }

  const currentPortfolioAccess = checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.CURRENT_PORTFOLIO }) || false

  const uploadPortfolioAccess = filterSecureData && filterSecureData[0]?.accountType === 'TRADING'
    ? checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.IMPORT_TRADING_PORTFOLIO })
    : filterSecureData && filterSecureData[0]?.accountType === 'TRANSITION'
      ? checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.IMPORT_TRANSITION_PORTFOLIO })
      : false

  const portfolioTabList = [
    ...(currentPortfolioAccess ? ['Current Portfolio'] : []),
    ...(uploadPortfolioAccess ? ['Upload Portfolio'] : [])
  ]

  useEffect(() => {
    if (user) {
      if (currentPortfolioAccess) {
        getTaxlot()
      } else {
        setIsTaxlotLoading(false)
      }
    }
  }, [user, params?.accountId])

  const getCompanyWiseRows = (originalData) => {
    const parentRows = {}
    originalData?.forEach(item => {
      const currItem = item.company
      if (parentRows?.hasOwnProperty(currItem)) {
        parentRows[currItem].shares += item.shares
        parentRows[currItem].purchaseCost += item.purchaseCost
        parentRows[currItem].purchaseDate = null
        parentRows[currItem].symbol = null
        parentRows[currItem].isoCountryName = item.isoCountryName
      } else {
        const companyObj = {}
        companyObj.id = randomId()
        companyObj.hierarchy = [item.company]
        companyObj.shares = item.shares
        companyObj.purchaseCost = item.purchaseCost
        companyObj.purchaseDate = null
        companyObj.symbol = null
        companyObj.isoCountryName = item.isoCountryName
        companyObj.name = item.company
        parentRows[currItem] = companyObj
      }
    })

    const childRows = {}
    originalData?.forEach(item => {
      if (childRows.hasOwnProperty(item.company)) {
        // if current company is added in childJson, push row in existing value array
        childRows[item.company].push({
          id: randomId(),
          instrId: item.instrId,
          hierarchy: [item.company, item.instrId + randomId()],
          name: item.instrName,
          shares: item.shares,
          purchaseCost: item.purchaseCost,
          purchaseDate: item.purchaseDate,
          symbol: item.instrId === '__USD' ? '__CASH' : item.instrId,
          isoCountryName: item.isoCountryName
        })
      } else {
        // push current company key in childJson
        childRows[item.company] = [{
          id: randomId(),
          instrId: item.instrId,
          hierarchy: [item.company, item.instrId + randomId()],
          name: item.instrName,
          shares: item.shares,
          purchaseCost: item.purchaseCost,
          purchaseDate: item.purchaseDate,
          symbol: item.instrId === '__USD' ? '__CASH' : item.instrId,
          isoCountryName: item.isoCountryName
        }]
      }
    })

    let finalArray = []
    for (const key in parentRows) {
      finalArray.push(parentRows[key])
      finalArray = finalArray.concat(childRows[key])
    }
    return finalArray
  }

  const getTaxlot = async () => {
    try {
      const response = await API.get(
        'baseURL',
        `account-master/v1/${user?.userGroup}/accounts/taxlots/${params.accountId}`
      )

      if (!response?.data?.length) {
        setNoDataMsg(response.message)
      } else if (response?.data?.length) {
        const downloadFileData = response.data.map((item, index) => {
          const dateObject = new Date(item.purchaseDate)
          const formattedDate = `${dateObject.getUTCFullYear()}${(dateObject.getUTCMonth() + 1).toString().padStart(2, '0')}${dateObject.getUTCDate().toString().padStart(2, '0')}`
          return { ...item, purchaseDate: formattedDate }
        })
        setOriginalTaxlot(response.data.map(obj => ({ ...obj, id: randomId() })))
        setTaxLotDataDownloadFormat(downloadFileData)
        const companyWiseTaxlotData = getCompanyWiseRows(response.data)
        setCompanyWiseTaxlot(companyWiseTaxlotData)
        setCompanyDataDownloadFormat(companyWiseTaxlotData?.filter(rowObj => rowObj.hierarchy?.length === 1))
      }
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    } finally {
      setIsTaxlotLoading(false)
    }
  }

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const renderTabPanel = (tabName) => {
    switch (tabName) {
      case 'Current Portfolio': {
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)} onReset={() => window.location.reload()}>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Box width='100%' display='flex' justifyContent='space-between' flexWrap='wrap' my={1}>
                    <RadioGroup
                      row
                      name='portfolioLevel'
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      value={portfolioLevel}
                      onChange={(e) => {
                        setPortfolioLevel(e.target.value)
                      }}
                    >
                      <FormControlLabel
                        value='Taxlot'
                        control={<Radio size='small' />}
                        label='Taxlot'
                      />
                      <FormControlLabel
                        value='Company'
                        control={<Radio size='small' />}
                        label='Company'
                      />
                    </RadioGroup>
                    <Box display='flex' alignItems='center' visibility={originalTaxlot?.length ? 'visible' : 'hidden'}>
                      <span className='import-portfolio-download-button'>
                        {
                        portfolioLevel === 'Company'
                          ? (
                            <CSVLink {...displayedCompanyCsvConfig}>
                              <Button sx={{ textTransform: 'none' }} startIcon={<FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px' }} />}>
                                Download Current Company Portfolio
                              </Button>
                            </CSVLink>
                            )
                          : (
                            <CSVLink {...displayedTaxlotCsvConfig}>
                              <Button sx={{ textTransform: 'none' }} startIcon={<FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px' }} />}>
                                Download Current Taxlot Portfolio
                              </Button>
                            </CSVLink>
                            )
                      }
                      </span>
                      <span className='import-portfolio-download-button'>
                        <CSVLink {...importFormatCsvConfig}>
                          <Button sx={{ textTransform: 'none' }} startIcon={<FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px' }} />}>
                            Download Portfolio In Import Format
                          </Button>
                        </CSVLink>
                      </span>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <CurrentImport taxlotData={originalTaxlot || []} portfolioLevel={portfolioLevel} companyWisePortfolio={companyWiseTaxlot} noDataMsg={noDataMsg} isTaxlotLoading={isTaxlotLoading} />
                </Grid>
              </Grid>
            </Box>
          </ErrorBoundary>
        )
      }
      case 'Upload Portfolio': {
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)} onReset={() => window.location.reload()}>
            <Grid container>
              <Grid item xs={12}>
                <UploadForm getTaxlot={getTaxlot} />
              </Grid>
            </Grid>
          </ErrorBoundary>
        )
      }
      default:
        return 'Something went wrong'
    }
  }

  return (
    <>
      <Box className='import-portfolio'>
        <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen='not main' />)} onReset={getTaxlot}>
          <AccountTitle title='Portfolio' />
        </ErrorBoundary>
        <TabContext value={currentTab}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <TabList
              onChange={handleTabChange}
              variant='scrollable'
              scrollButtons={false}
              id='portfolio-tabs'
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#34475A'
                }
              }}
              indicatorColor='#34475A'
            >
              {
                portfolioTabList?.map((tabName) => (<Tab className='tab-header' value={tabName} label={tabName} key={tabName} />))
              }
            </TabList>
          </Box>
          <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)'
          }}
          />
          {
            portfolioTabList?.map((tabName) => (
              <TabPanel
                value={tabName}
                key={tabName}
                sx={{
                  p: 0
                }}
              >
                {renderTabPanel(tabName)}
              </TabPanel>
            ))

          }
        </TabContext>
      </Box>
    </>
  )
}
export default AccountPortfolioImport
