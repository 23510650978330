import { useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button } from '@mui/material'
import { addEdge, Background, BackgroundVariant, ReactFlow, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow } from '@xyflow/react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ELK from 'elkjs/lib/elk.bundled.js'
import '@xyflow/react/dist/style.css'
import { disableLegacyViewReducer } from '../../../../store/trade-reducer/trade-reducer'
import AccountNode from './AccountNode'
import CustomEdge from './CustomEdge'

const nodeTypes = {
  custom: AccountNode
}

const edgeTypes = {
  custom: CustomEdge
}

const elk = new ELK()
const elkOptions = {
  'elk.algorithm': 'layered',
  'elk.layered.spacing.nodeNodeBetweenLayers': '400',
  'elk.spacing.nodeNode': '40'
}

const getLayoutedElements = async (nodes, edges, options = {}) => {
  const isHorizontal = options?.['elk.direction'] === 'RIGHT'
  const graph = {
    id: 'root',
    layoutOptions: options,
    children: nodes.map((node) => ({
      ...node,
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',
    })),
    edges
  }

  return elk
    .layout(graph)
    .then((layoutedGraph) => ({
      nodes: layoutedGraph.children.map((node) => ({
        ...node,
        position: { x: node.x, y: node.y },
      })),
      edges: layoutedGraph.edges,
    }))
    .catch(console.error)
}

const LayoutFlow = ({ data }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const { fitView } = useReactFlow()
  const [clickedNodes, setClickedNodes] = useState([])
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  )

  const onLayout = useCallback(({ direction, useInitialNodes = false }) => {
    const opts = { 'elk.direction': direction, ...elkOptions }
    const ns = useInitialNodes ? data?.flowChartNodes || [] : nodes
    const es = useInitialNodes ? data?.flowChartEdges || [] : edges

    getLayoutedElements(ns, es, opts).then(
      ({ nodes: layoutedNodes, edges: layoutedEdges }) => {
        setNodes(layoutedNodes)
        setEdges(layoutedEdges)
        window.requestAnimationFrame(() => fitView())
      },
    )
  }, [nodes, edges])

  useLayoutEffect(() => {
    onLayout({ direction: 'RIGHT', useInitialNodes: true })
  }, [])

  const handleNodeClick = (node) => {
    // update clicked node array
    let updatedClickedNodes = []
    if (clickedNodes.includes(node.id)) {
      updatedClickedNodes = clickedNodes.filter(nodeId => nodeId !== node.id)
    }
    else {
      updatedClickedNodes = [...clickedNodes, node.id]
    }
    setClickedNodes(updatedClickedNodes)

    // find edges which has either source or target node as clicked
    const filteredEdged = edges.filter(edgeObj => updatedClickedNodes.includes(edgeObj.source) || updatedClickedNodes.includes(edgeObj.target))
    const filteredNodes = new Set(filteredEdged.flatMap(obj => [obj.source, obj.target]))

    setEdges(prevEdges => prevEdges.map(edgeObj => {
      if (!updatedClickedNodes?.length) {
        return { ...edgeObj, data: { ...edgeObj.data, showLabel: false }, animated: false, style: { ...edgeObj.style, stroke: '#aaaaaa', opacity: 1 } }
      }
      else if (filteredEdged.find(edge => edge.id === edgeObj.id)) {
        return { ...edgeObj, data: { ...edgeObj.data, showLabel: true }, animated: true, style: { ...edgeObj.style, stroke: '#3BBFA3', opacity: 1 } }
      }
      else {
        return { ...edgeObj, data: { ...edgeObj.data, showLabel: false }, animated: false, style: { ...edgeObj.style, stroke: '#aaaaaa', opacity: 0.2 } }
      }
    }))

    setNodes(prevNodes => prevNodes.map(nodeObj => {
      if (!updatedClickedNodes?.length) {
        return { ...nodeObj, style: { opacity: 1 } }
      }
      else if (!filteredNodes.has(nodeObj.id)) {
        return { ...nodeObj, style: { opacity: 0.2 } }
      }
      else if (updatedClickedNodes.includes(nodeObj.id)) {
        return { ...nodeObj, style: { opacity: 1, border: '3px solid #3BBFA3' } }
      }
      else {
        return { ...nodeObj, style: { opacity: 1 } }
      }
    }))
  }

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      proOptions={{ hideAttribution: true, account: 'paid-pro' }}
      nodesConnectable={false}
      nodesDraggable={false}
      fitView
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodeClick={(e, node) => handleNodeClick(node)}
      onClick={(e) => {
        document.body.dispatchEvent(new Event('mousedown'))
      }}
    >
      <Background variant={BackgroundVariant.Dots} />
    </ReactFlow>
  )
}

const ExpandedGroupView = (props) => {
  const dispatch = useDispatch()

  const disableLegacyView = () => {
    dispatch(disableLegacyViewReducer())
  }

  return (
    <Box sx={{
      background: 'white',
      height: 'calc(100vh - 48px - 15px - 20px)',
      width: '100%',
      position: 'relative'
    }}
    >
      <ReactFlowProvider>
        <LayoutFlow {...props} />
      </ReactFlowProvider>
      <Button
        startIcon={<ArrowBackIosNewIcon sx={{ height: '14px', width: '14px' }} />}
        sx={{ position: 'absolute', top: 0, background: 'white', ':hover': { background: 'white' } }}
        onClick={disableLegacyView}
      >
        Dashboard
      </Button>
    </Box>
  )
}

export default ExpandedGroupView
