import React, { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SaveIcon from '@mui/icons-material/Save'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Button, IconButton, Menu, MenuItem } from '@mui/material'
import {
  GridActionsCellItem,
  GridRowModes
} from '@mui/x-data-grid'

const AllocationActionCell = (props) => {
  const { params, apiRef, rowModesModel, handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick, hasStrategyError, setViewPortfolioPopup, setCurrentPortfolioId, hasPortfolioData, setTransitionUploadPortfolioData } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [activeRow, setActiveRow] = useState(null)

  const isInEditMode = rowModesModel[params?.id]?.mode === GridRowModes.Edit
  const currentRowValues = apiRef?.current?.getRowWithUpdatedValues(params?.id)

  if (isInEditMode) {
    return [
      <GridActionsCellItem
        icon={<SaveIcon />}
        key='save'
        disabled={hasStrategyError || !currentRowValues?.account || currentRowValues?.account?.length < 3}
        label='Save'
        sx={{ color: 'primary.main' }}
        onClick={() => handleSaveClick(params?.id)}
      />,
      <GridActionsCellItem
        icon={<CancelIcon />}
        label='Cancel'
        key='cancel'
        className='textPrimary'
        onClick={() => handleCancelClick(params?.id)}
        color='inherit'
      />
    ]
  }

  return (
    <>
      <IconButton
        aria-label='more'
        aria-haspopup='true'
        onClick={(event) => {
          setAnchorEl(event.currentTarget)
          setActiveRow(params.row)
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && activeRow?.id === params.row?.id}
        onClose={() => {
          setActiveRow(null)
          setAnchorEl(null)
        }}
        elevation={4}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          padding: 0,
          '.MuiMenuItem-root': { padding: '0 10px' },
          '.MuiButtonBase-root': { justifyContent: 'flex-start', textTransform: 'capitalize' }
        }}
      >
        <MenuItem>
          <Button
            fullWidth
            variant='text'
            sx={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: 600 }}
            onClick={() => {
              setAnchorEl(null)
              handleEditClick(params?.id)
            }}
            disableRipple
            startIcon={<ManageAccountsOutlinedIcon sx={{ fontSize: '8px' }} />}
          >
            Edit Account
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            fullWidth
            variant='text'
            sx={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: 600 }}
            onClick={() => {
              setAnchorEl(null)
              setCurrentPortfolioId(params?.id)
              setViewPortfolioPopup(true)
            }}
            disabled={
              Object.keys(hasPortfolioData)?.length && hasPortfolioData[params?.row?.id]?.fundingInformation?.fundingSource === 'CASH'
                ? false
                : !(Object.keys(hasPortfolioData)?.length && hasPortfolioData[params?.row?.id]?.data?.length)
            }
            disableRipple
            startIcon={<VisibilityOutlinedIcon sx={{ fontSize: '8px' }} />}
          >
            View Portfolio
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            fullWidth
            variant='text'
            sx={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: 600 }}
            onClick={() => {
              setAnchorEl(null)
              setTransitionUploadPortfolioData((prev) => {
                const updatedPortfolioData = { ...prev }
                delete updatedPortfolioData[params?.row?.id]
                return updatedPortfolioData
              })
            }}
            disabled={
              Object.keys(hasPortfolioData)?.length && hasPortfolioData[params?.row?.id]?.fundingInformation?.fundingSource === 'CASH'
                ? false
                : !(Object.keys(hasPortfolioData)?.length && hasPortfolioData[params?.row?.id]?.data?.length)
            }
            disableRipple
            startIcon={<CloseIcon sx={{ fontSize: '8px' }} />}
          >
            Remove Portfolio
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            fullWidth
            sx={{ color: 'red', fontWeight: 600 }}
            variant='text'
            disableRipple
            onClick={() => {
              handleDeleteClick(params?.id)
              setCurrentPortfolioId(params?.id)
              setAnchorEl(null)
            }}
            startIcon={<DeleteOutlineOutlinedIcon sx={{ fontSize: '8px' }} />}
          >
            Delete Account
          </Button>
        </MenuItem>
      </Menu>
    </>
  )
}

export default AllocationActionCell
