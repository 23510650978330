import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Popover, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { Close, FilterAltOutlined, FilterAlt, FileDownloadOutlined } from '@mui/icons-material'
import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'

const getTradeQuantityColor = (category) => {
  if (category === 'SELL') {
    return '#FF6A6A'
  }
  else if (category === 'BUY') {
    return '#33A68D'
  }
  else return 'inherit'
}

const tradeListColumns = [
  {
    headerName: 'Security Name',
    field: 'securityName',
    flex: 1
  },
  {
    headerName: 'Local Symbol',
    field: 'localSymbol',
    flex: 1
  },
  {
    headerName: 'Category',
    field: 'category',
    flex: 1,
    renderCell: (params) => <Box sx={{ color: getTradeQuantityColor(params?.row?.category) }}>{params.value}</Box>
  },
  {
    headerName: 'Source Account',
    field: 'sourceAccountName',
    flex: 1
  },
  {
    headerName: 'Destination Account',
    field: 'destinationAccountName',
    flex: 1
  },
  {
    headerName: 'Trade Quantity',
    field: 'tradeQuantity',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    renderCell: (params) => (
      <Box>
        {params?.row?.tradeQuantity !== null && params?.row?.tradeQuantity !== undefined
          ? params?.row?.tradeQuantity
          : ''}
      </Box>
    )
  },
  {
    headerName: 'Trade Value',
    field: 'tradeValue',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    renderCell: (params) => (
      <Box>
        {params?.row?.tradeValue !== null && params?.row?.tradeValue !== undefined
          ? formatCurrencyWithSymbol(params?.row?.tradeValue, 2, '$')
          : ''}
      </Box>
    )
  }
]

const TradeList = ({ data = [], loading }) => {
  const [tradeListData, setTradeListData] = useState(data)
  const [anchorEl, setAnchorEl] = useState(null)
  const [filterModel, setFilterModel] = useState({ srcAccount: null, destAccount: null })
  const [srcAccNames, setSrcAccNames] = useState([])
  const [destAccNames, setDestAccNames] = useState([])
  const [selectedTradeCategory, setSelectedTradeCategory] = useState({ buy: true, sell: true, transfer: true, nop: false })

  useEffect(() => {
    setSrcAccNames([...new Set(data.map(obj => obj?.sourceAccountName).filter(names => names !== null))])
    setDestAccNames([...new Set(data.map(obj => obj?.destinationAccountName).filter(names => names !== null))])
  }, [data])

  useEffect(() => {
    setTradeListData(data.filter(obj => (
      (filterModel.srcAccount ? filterModel.srcAccount === obj?.sourceAccountName : true)
      &&
      (filterModel.destAccount ? filterModel.destAccount === obj?.destinationAccountName : true)
      &&
      selectedTradeCategory[obj?.category?.toLowerCase()]
    )))
  }, [data, selectedTradeCategory, filterModel])

  const resetFilter = () => {
    setFilterModel({ srcAccount: null, destAccount: null })
  }

  const handleCategoryCheckboxChange = (event) => {
    const updatedSelectedTradeCategory = {
      ...selectedTradeCategory,
      [event.target.name]: event.target.checked
    }
    setSelectedTradeCategory(updatedSelectedTradeCategory)
  }

  const downloadTradeListCsv = () => {
    const dataCsv = [['Security Name', 'Local Symbol', 'Category', 'Source Account ID', 'Source Account Name', 'Destination Account ID', 'Destination Account Name', 'Trade Quantity', 'Trade Value']?.join('|')]

    for (let i = 0; i < data?.length; i++) {
      const { securityName, localSymbol, category, sourceAccountId, sourceAccountName, destinationAccountId, destinationAccountName, tradeQuantity, tradeValue } = data[i]
      dataCsv.push([securityName || '', localSymbol || '', category || '', sourceAccountId || '', sourceAccountName || '', destinationAccountId || '', destinationAccountName || '', tradeQuantity, tradeValue].join('|'))
    }
    const csvBlob = new Blob([dataCsv.join('\n')], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvBlob)

    // Create a temporary link and trigger the download
    const tempLink = document.createElement('a')
    tempLink.href = csvUrl
    tempLink.setAttribute('download', 'trade-list.csv')
    tempLink.click()

    // Clean up the URL object
    URL.revokeObjectURL(csvUrl)
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '36px' }}>
          <Typography sx={{ fontSize: '16px', color: '#74788D', fontWeight: 600 }}>Trade List</Typography>
          <Box sx={{
            display: 'inline-flex',
            alignItems: 'center',
            '& .MuiCheckbox-root': {
              padding: '4px'
            },
            '& .MuiFormControlLabel-label': {
              fontSize: '14px'
            }
          }}>
            <FormControl>
              <FormLabel id='trade-controlled-checkbox-buttons-group' />
              <FormGroup row name='trade-checkbox-group' >
                <FormControlLabel
                  value='SELL' control={<Checkbox
                    size='small'
                    checked={selectedTradeCategory.sell}
                    onChange={handleCategoryCheckboxChange}
                  />} label='Sell' name='sell'
                />
                <FormControlLabel
                  value='BUY' control={<Checkbox
                    size='small'
                    checked={selectedTradeCategory.buy}
                    onChange={handleCategoryCheckboxChange}
                  />} label='Buy' name='buy'
                />
                <FormControlLabel
                  value='TRANSFER'
                  control={<Checkbox
                    size='small'
                    checked={selectedTradeCategory.transfer}
                    onChange={handleCategoryCheckboxChange}
                  />} label='Transfer' name='transfer'
                />
                <FormControlLabel
                  value='NOP'
                  control={<Checkbox
                    size='small'
                    checked={selectedTradeCategory.nop}
                    onChange={handleCategoryCheckboxChange}
                  />} label='NOP' name='nop'
                />
              </FormGroup>
            </FormControl>
            <Tooltip title='Download CSV'>
              <IconButton onClick={downloadTradeListCsv} disabled={!data?.length}>
                <FileDownloadOutlined fontSize='small' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Filter accounts'>
              <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                {
                  filterModel.srcAccount || filterModel.destAccount
                    ? <FilterAlt fontSize='small' />
                    : <FilterAltOutlined fontSize='small' />
                }
              </IconButton>
            </Tooltip>
          </Box>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box sx={{ width: '420px', p: '8px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '14px', color: '#34475A' }}>Add Filter</Typography>
                <IconButton onClick={() => setAnchorEl(null)}>
                  <Close sx={{ height: '18px', width: '18px' }} />
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', gap: '10px', mt: '5px' }}>
                <Autocomplete
                  value={filterModel.srcAccount}
                  options={srcAccNames}
                  onChange={(e, value) => setFilterModel({ ...filterModel, srcAccount: value })}
                  size='small'
                  fullWidth
                  filterSelectedOptions
                  ListboxProps={{ sx: { fontSize: 14, py: 1 } }}
                  renderInput={(params) => <TextField {...params} label='Source Account' variant='outlined' />}
                />
                <Autocomplete
                  value={filterModel.destAccount}
                  options={destAccNames}
                  onChange={(e, value) => setFilterModel({ ...filterModel, destAccount: value })}
                  size='small'
                  fullWidth
                  filterSelectedOptions
                  ListboxProps={{ sx: { fontSize: 14, py: 1 } }}
                  renderInput={(params) => <TextField {...params} label='Destination Account' variant='outlined' />}
                />
              </Box>
              <Divider sx={{ mt: '10px' }} />
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button onClick={resetFilter} sx={{ fontSize: '14px', textTransform: 'capitalize', color: '#74788D' }}>
                  Clear Filters
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box sx={{ position: 'relative', mt: '10px' }}>
          {
            loading ? (
              <TableContainer mt={10}>
                <Table className='risk-page-table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      {tradeListColumns.map((item, index) => {
                        return (
                          <TableCell key={index}>{item.headerName}</TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 5 }).map((_, i) => (
                      <TableRow key={i}>
                        {
                          Array.from({ length: tradeListColumns.length + 1 }).map((_, j) => (
                            <TableCell key={j}>
                              <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                            </TableCell>
                          ))
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <DataGridPro
                autoHeight
                density='compact'
                rows={tradeListData}
                columns={tradeListColumns}
                getRowId={(row) => row?.id}
                pagination
                pageSizeOptions={[10]}
                initialState={{
                  ...tradeListData?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
                sx={(theme) => ({
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  [`.${gridClasses.main}`]: {
                    overflow: 'unset'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    backgroundColor: theme.palette.background.paper,
                    top: 0,
                    zIndex: 1,
                  },
                  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                    color: '#74788d',
                    fontWeight: 600
                  },
                  [`.${gridClasses.virtualScroller}`]: {
                    overflowY: 'auto !important',
                    scrollbarGutter: 'stable',
                    scrollbarWidth: 'none'
                  }
                })}
              />
            )
          }
        </Box>
      </Grid>
    </Grid>
  )
}

export default TradeList