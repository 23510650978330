import React from 'react'
import { Grid, Typography, Box, Divider } from '@mui/material'

const AllComments = ({ data, name }) => {
  return (
    <Box className='comment-box'>
      <Grid container spacing={2} className='account-request' sx={{ width: '100% !important', marginTop: '0px !important', marginLeft: '0px !important' }}>
        {(data || []).map((item, index) =>
          <Grid item xs={12} key={`${index}`} sx={{ paddingLeft: '8px !important', paddingTop: '8px !important', padding: '8px !important' }}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item>
                <Typography className='time' sx={{ fontFamily: 'Open Sans' }}>
                  {
                    item?.createdBy
                      ? (
                        <>
                          {item?.createdBy || ''}
                          <span style={{ marginLeft: '3px', marginRight: '3px' }}>-</span>
                        </>
                        )
                      : (
                        <></>
                        )
                  }
                  {new Date(item?.insTs).toDateString()}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className='awaiting-badge' sx={{ fontSize: '10px', fontFamily: 'Open Sans' }}>
                  {item.statusType[0]}
                  {item.statusType.slice(1, 12).toLowerCase()}
                </Typography>
              </Grid>
            </Grid>
            <Typography className='reqComment' sx={{ fontFamily: 'Open Sans' }}>
              {item.comment}
            </Typography>
            {index !== data.length - 1 && <Divider sx={{ background: '#CACACA', marginTop: '8px' }} />}
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default AllComments
