import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import { AttachMoneyOutlined, MailOutline, PercentOutlined, PersonOutlineOutlined } from '@mui/icons-material'
import { Autocomplete, Box, Button, Card, CardContent, Checkbox, Collapse, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { isNumberOnly, isNumberWithSign, isWholeNumber } from '../../../utils/NumberUtils'
import { InputAdornmentTextField } from '../../AccountOnboarding/components/inputAdornmentTextfield'

const UMADetailsTab = ({ data, setData, submitRef, sellLogicCodes, custodianList, taxStates, setAllocationCardData, setTransitionUploadPortfolioData, setTransitionPortfolioAllocationData, setAllStepsVisited, sellLogicCodesLoading, taxStatesLoading, setUmaDetailsFormChanged }) => {
  const validationSchema = Yup.object({
    accountType: Yup.string().oneOf(['transition', 'trading']),
    name: Yup.string()
      .trim()
      .required('Name required')
      .max(100, 'Name can not be more than 100 characters')
      .min(3, 'Name can not be less than 3 characters'),
    email: Yup.string()
      .trim()
      .email('Invalid email')
      .required('Email required')
      .max(255, 'Email can not be more than 255 characters')
      .min(1, 'Email can not be less than 1 character'),
    selllogicCode: Yup.object().nullable().required('Tax Account Method required'),
    custodian: Yup.object()
      .nullable() // Allows custodian to be null
      .when('accountType', ([accountType], schema) => {
        if (accountType && accountType === 'trading') { return schema.required('Custodian required') }
        return schema
      }),
    // custodianName: Yup.string(),
    custodianNumber: Yup.string()
      .trim()
      .max(45, 'Custodian number can not be more than 45 characters')
      .when('accountType', ([accountType], schema) => {
        if (accountType && accountType === 'trading') { return schema.required('Custodian Number required').min(1, 'Custodian Number can not be less than 1 character') }
        return schema
      }),
    masterAccountId: Yup.string()
      .max(45, 'Master Account ID can not be more than 45 characters')
      .when(['accountType', 'custodian'], ([accountType, custodian], schema) => {
        if (accountType && accountType === 'trading' && custodian?.custodianName === 'Charles Schwab') { return schema.required('Master Account ID required').min(1, 'Master Account ID can not be less than 1 character') }
        return schema
      }),
    taxManaged: Yup.string().oneOf(['Yes', 'No']),
    taxState: Yup.object().nullable()
      .when('taxManaged', ([taxManaged], schema) => {
        if (taxManaged && taxManaged === 'Yes') { return schema.required('Tax state required') }
        return schema
      }),
    shortTermTaxRate: Yup.string()
      .when('taxManaged', ([taxManaged], schema) => {
        if (taxManaged && taxManaged === 'Yes') {
          return schema.required('Short term tax rate required')
            .test('valid-range', 'Short term tax rate must be between 0 and 100', value => {
              const numericValue = parseFloat(value)
              return numericValue >= 0 && numericValue <= 100
            })
        }
        return schema
      }),
    longTermTaxRate: Yup.string()
      .when('taxManaged', ([taxManaged], schema) => {
        if (taxManaged && taxManaged === 'Yes') {
          return schema.required('Long term tax rate required')
            .test('valid-range', 'Long term tax rate must be between 0 and 100', value => {
              const numericValue = parseFloat(value)
              return numericValue >= 0 && numericValue <= 100
            })
        }
        return schema
      }),
    showAdvancedTax: Yup.boolean(),
    externalLongGainLoss: Yup.string()
      .when(['showAdvancedTax', 'taxManaged'], ([showAdvancedTax, taxManaged], schema) => {
        if (showAdvancedTax && taxManaged === 'Yes') { return schema.required('External realized long gain or losses required') }
        return schema
      }),
    externalShortGainLoss: Yup.string()
      .when(['showAdvancedTax', 'taxManaged'], ([showAdvancedTax, taxManaged], schema) => {
        if (showAdvancedTax && taxManaged === 'Yes') { return schema.required('External realized short gain or losses required') }
        return schema
      })
  })

  const formik = useFormik({
    initialValues: Object.keys(data).length === 0
      ? {
          accountType: 'transition',
          taxManaged: 'Yes',
          showAdvancedTax: false,
          name: '',
          email: '',
          selllogicCode: null,
          custodian: null,
          custodianNumber: '',
          masterAccountId: '',
          taxSensitivity: '',
          shortTermTaxRate: '',
          longTermTaxRate: '',
          externalLongGainLoss: '',
          externalShortGainLoss: '',
          taxState: null
        }
      : {
          ...data,
          taxManaged: data?.taxManaged === true ? 'Yes' : 'No',
          taxSensitivity: data?.taxManaged === 'Yes' ? 'MEDIUM' : 'LOW'
        },
    validationSchema,
    onSubmit: (values) => {
      setData({ ...values, taxManaged: values?.taxManaged === 'Yes', taxSensitivity: values?.taxManaged === 'Yes' ? 'MEDIUM' : 'LOW' })
    }
  })

  // Effect to track the changes in the formik data
  useEffect(() => {
    if (formik.dirty) {
      setUmaDetailsFormChanged(true)
    } else {
      setUmaDetailsFormChanged(false)
    }
  }, [formik.dirty])

  return (
    <Box>
      <FormikProvider value={formik}>
        <form noValidate onSubmit={formik.handleSubmit} autoComplete='off'>
          <Button
            ref={submitRef}
            type='submit'
            style={{ display: 'none' }}
          />
          <Card className='card-layout'>
            <CardContent>
              <Typography className='account-label-header' mb={2}>
                Basic Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} display='flex' alignItems='center' mb={2}>
                  <Typography variant='body1' component='label' htmlFor='accountType'>
                    Account Type *
                  </Typography>
                  <Box mx={2}>
                    <Field name='accountType'>
                      {({ field }) => (
                        <RadioGroup
                          row
                          aria-labelledby='demo-row-radio-buttons-group-label'
                          value={formik?.values?.accountType || 'transition'}
                          onChange={(e) => {
                            formik.setFieldValue('accountType', e.target.value)
                            formik.setErrors({})
                          }}
                          {...field}
                        >
                          <FormControlLabel
                            value='transition'
                            control={<Radio size='small' />}
                            label='Transition'
                            onClick={() => {
                              formik.setFieldValue('masterAccountId', '')
                              setAllocationCardData([{ id: 1, data: [] }])
                              setTransitionUploadPortfolioData({})
                              setAllStepsVisited(false)
                              setTransitionPortfolioAllocationData([])
                            }}
                          />
                          {/* <FormControlLabel
                            value='trading'
                            control={<Radio size='small' />}
                            label='Trading'
                            onClick={() => {
                              setAllocationCardData([{ id: 1, data: [] }])
                              setTransitionUploadPortfolioData({})
                              setAllStepsVisited(false)
                              setTransitionPortfolioAllocationData([])
                            }}
                          /> */}
                        </RadioGroup>
                      )}
                    </Field>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} mb={2}>
                  <InputAdornmentTextField
                    id='name'
                    name='name'
                    type='text'
                    label='Name *'
                    placeholder='Enter Name'
                    sx={{
                      width: '100%',
                      marginTop: '0px'
                    }}
                    adornment={<PersonOutlineOutlined sx={{
                      width: '20px !important',
                      height: '20px !important'
                    }}
                               />}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} mb={2}>
                  <InputAdornmentTextField
                    id='email'
                    name='email'
                    type='text'
                    label='Client Email *'
                    placeholder='Enter email'
                    sx={{
                      width: '100%',
                      marginTop: '0px'
                    }}
                    adornment={<MailOutline sx={{
                      height: '20px !important'
                    }}
                               />}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} mb={2}>
                  <Autocomplete
                    options={sellLogicCodes || []}
                    loading={sellLogicCodesLoading}
                    getOptionLabel={(option) => `${option.sellLogicCode} - ${option.sellLogicDesc}`}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('selllogicCode', newValue)
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.selllogicCode || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Tax Accounting Method for Account *'
                        error={formik.touched.selllogicCode && Boolean(formik.errors.selllogicCode)}
                        helperText={formik.touched.selllogicCode && formik.errors.selllogicCode}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                {formik?.values?.accountType === 'trading'
                  ? <>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <Box>
                        <Autocomplete
                          id='custodian'
                          options={custodianList}
                          value={formik?.values?.custodian}
                          getOptionLabel={(option) => option?.custodianName}
                          onChange={async (event, value) => {
                            await formik?.setFieldValue('custodian', value)
                            // Mark the field as touched and immediately validate it
                            await formik?.setFieldTouched('custodian', true, true)
                            formik.validateField('custodian')
                            if (value?.custodianName !== 'Charles Schwab') {
                              formik?.setFieldValue('masterAccountId', '')
                            }
                            setAllStepsVisited(false)
                          }}
                          isOptionEqualToValue={(option, value) => option?.custodianId === value?.custodianId}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onBlur={() => {
                                formik.setFieldTouched('custodian', true)
                                formik.validateField('custodian')
                              }}
                              error={formik.touched.custodian && Boolean(formik.errors.custodian)}
                              helperText={formik.touched.custodian && formik.errors.custodian}
                              label='Custodian *'
                              name='custodian'
                            />
                          )}
                          required
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <TextField
                        id='custodian-number'
                        name='custodianNumber'
                        type='text'
                        label='Custodian Account Number *'
                        placeholder='Enter number here'
                        onKeyDown={(e) =>
                          formik?.values?.custodian?.custodianName === 'Charles Schwab' ? isWholeNumber(e) : true}
                        onPaste={(event) => {
                          if (formik?.values?.custodian?.custodianName === 'Charles Schwab') {
                            const inputValue = event.clipboardData.getData('text')
                            const regex = /^\d+$/
                            if (!regex.test(inputValue)) {
                              event.target.value = ''
                              event.preventDefault()
                              return false
                            }
                            return true
                          }
                        }}
                        value={formik.values.custodianNumber}
                        onChange={(e) => {
                          setAllStepsVisited(false)
                          formik.setFieldValue('custodianNumber', e.target.value)
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched.custodianNumber && Boolean(formik.errors.custodianNumber)}
                        helperText={formik.touched.custodianNumber && formik.errors.custodianNumber}
                        sx={{
                          width: '100%'
                        }}
                      />
                    </Grid>
                    {formik?.values?.custodian?.custodianName === 'Charles Schwab'
                      ? <Grid item xs={12} sm={6} md={4} mb={2}>
                        <TextField
                          id='master-account-id'
                          name='masterAccountId'
                          type='text'
                          label='Master Account ID *'
                          placeholder='Enter account ID here'
                          onKeyDown={(e) => isWholeNumber(e)}
                          onPaste={(event) => {
                            const inputValue = event.clipboardData.getData('text')
                            const regex = /^\d+$/
                            if (!regex.test(inputValue)) {
                              event.target.value = ''
                              event.preventDefault()
                              return false
                            }
                            return true
                          }}
                          value={formik.values.masterAccountId}
                          onChange={(e) => {
                            setAllStepsVisited(false)
                            formik.setFieldValue('masterAccountId', e.target.value)
                          }}
                          onBlur={formik.handleBlur}
                          error={formik.touched.masterAccountId && Boolean(formik.errors.masterAccountId)}
                          helperText={formik.touched.masterAccountId && formik.errors.masterAccountId}
                          sx={{
                            width: '100%'
                          }}
                        />
                      </Grid>
                      : ''}
                    </>
                  : <></>}
              </Grid>
            </CardContent>
          </Card>
          <Card className='card-layout' sx={{ marginTop: '22px' }}>
            <CardContent>
              <Typography className='account-label-header' mb={2}>
                Tax Information ({new Date().getFullYear()})
              </Typography>
              <Box>
                <Box display='flex' alignItems='center' mb={2}>
                  <Typography variant='body1' component='label' style={{ marginRight: '16px' }} htmlFor='taxManaged'>
                    Is tax managed?
                  </Typography>
                  <Field name='taxManaged'>
                    {({ field }) => (
                      <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        {...field}
                      >
                        <FormControlLabel
                          value='Yes'
                          control={<Radio size='small' />}
                          label='Yes'
                        />
                        <FormControlLabel
                          value='No'
                          control={<Radio size='small' />}
                          label='No'
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </Box>
                <Collapse in={formik?.values?.taxManaged === 'Yes'}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <Autocomplete
                        options={taxStates || []}
                        loading={taxStatesLoading}
                        getOptionLabel={(option) => `${option.stateName}`}
                        onChange={(event, newValue) => {
                          formik.setFieldValue('taxState', newValue)
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.taxState || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Tax Accounting Method for Account *'
                            error={formik.touched.taxState && Boolean(formik.errors.taxState)}
                            helperText={formik.touched.taxState && formik.errors.taxState}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <TextField
                        id='short-term-label'
                        name='shortTermTaxRate'
                        type='text'
                        sx={{ width: '100%' }}
                        label='Short term tax rate *'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position='end'
                            ><PercentOutlined sx={{
                              width: 18,
                              height: 18
                            }}
                            />
                            </InputAdornment>
                          )
                        }}
                        onKeyDown={(event) => isNumberOnly(event)}
                        value={formik.values.shortTermTaxRate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.shortTermTaxRate && Boolean(formik.errors.shortTermTaxRate)}
                        helperText={formik.touched.shortTermTaxRate && formik.errors.shortTermTaxRate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <TextField
                        id='long-term-label'
                        name='longTermTaxRate'
                        type='text'
                        sx={{ width: '100%' }}
                        label='Long term tax rate *'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position='end'
                            >
                              <PercentOutlined sx={{
                                width: 18,
                                height: 18
                              }}
                              />
                            </InputAdornment>
                          )
                        }}
                        onKeyDown={(event) => isNumberOnly(event)}
                        value={formik.values.longTermTaxRate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.longTermTaxRate && Boolean(formik.errors.longTermTaxRate)}
                        helperText={formik.touched.longTermTaxRate && formik.errors.longTermTaxRate}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Box>
              {formik?.values?.taxManaged === 'Yes' && (
                <Box>
                  <Box mb={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik?.values?.showAdvancedTax}
                          onClick={(event) => formik?.setFieldValue('showAdvancedTax', event.target.checked)}
                          id='advance-tax'
                          name='showAdvancedTax'
                          size='small'
                        />
                      }
                      label='Advanced Taxes (Optional)'
                    />
                  </Box>
                  <Collapse in={formik?.values?.showAdvancedTax}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4} mb={2}>
                        <InputAdornmentTextField
                          id='external-long-gainloss-label'
                          name='externalLongGainLoss'
                          type='text'
                          label='External realized long gain or losses *'
                          onKeyDown={(event) => isNumberWithSign(event)}
                          sx={{
                            width: '100%'
                          }}
                          adornment={<AttachMoneyOutlined />}
                          value={formik.values.externalLongGainLoss}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.externalLongGainLoss && Boolean(formik.errors.externalLongGainLoss)}
                          helperText={formik.touched.externalLongGainLoss && formik.errors.externalLongGainLoss}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} mb={2}>
                        <InputAdornmentTextField
                          id='external-short-gainloss-label'
                          name='externalShortGainLoss'
                          type='text'
                          label='External realized short gain or losses *'
                          onKeyDown={(event) => isNumberWithSign(event)}
                          sx={{ width: '100%' }}
                          adornment={<AttachMoneyOutlined />}
                          value={formik.values.externalShortGainLoss}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.externalShortGainLoss && Boolean(formik.errors.externalShortGainLoss)}
                          helperText={formik.touched.externalShortGainLoss && formik.errors.externalShortGainLoss}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Box>
              )}
            </CardContent>
          </Card>
        </form>
      </FormikProvider>
    </Box>
  )
}

export default UMADetailsTab
