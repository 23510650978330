import React from 'react'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes
} from 'react-router-dom'
import ContentLayout from '../layout/ContentLayout'
import AccountDashboard from '../pages/AccountReview/Dashboard'
import AccountPortfolioImport from '../pages/AccountReview/ImportPortfolio'
import AccountRequest from '../pages/AccountReview/RequestsPage/AccountRequest'
import AccountOnboarding from '../pages/AccountOnboarding'
import BookOfBusiness from '../pages/BookofBusiness/index'
import { Index } from '../pages/Data Maintenance/Index'
import { UserEmailVerification } from '../pages/ForgotPassword/UserEmailVerification'
import { Evidence } from '../pages/IPS/Evidence'
import { Monitoring } from '../pages/IPS/Monitoring'
import PolicyPage from '../pages/IPS/Policy'
import { Statement } from '../pages/IPS/Statement'
import IPSPage from '../pages/IPS/components/IPSPage'
import Login from '../pages/Login'
import AllRequestTabs from '../pages/PMScreen/RequestsPage/AllRequestTabs'
import PageNotFound from '../pages/PageNotFound'
import Personalization from '../pages/Personalization'
import ReportPage from '../pages/Report/Index'
import RiskDashboard from '../pages/RiskDashboard/RiskDashboard'
import ListOfaAllAccount from '../pages/SelectListofAccount/selectelistofAccounts'
import StrategyImportPage from '../pages/StrategyPortfolio/index'
import TradeApprovalsMain from '../pages/Trade/TradeApprovals/TradeApprovalsMain'
import TradeBlotterMain from '../pages/Trade/TradeBlotter/TradeBlotterMain'
import TradeApprovedMain from '../pages/Trade/TrdaeApproved/TradeApprovedMain'
import ProcessMonitoring from '../pages/ProcessMonitoring'
import MfaQRCode from '../pages/mfa/index'
import AllStrategyList from '../pages/AllStrategyList'
import PrivateRoute from './PrivateRoute'
import TradeComparatorMain from '../pages/Trade/TradeComparator/TradeComparatorMain'
import { useAuth } from '../contexts/AuthContext'
import AggregateTreeView from '../pages/Aggregate/AggregateView/AggregateTreeView'
import PDFLayout from '../pages/PDFCustomizer/PDFLayout'
import IPSAggregate from '../pages/Aggregate/IPSAggregate'
import SelectFamilyAccount from '../pages/Aggregate/IPSAggregate/SelectFamilyAccount'
import SelectUmaAccount from '../pages/Aggregate/TaxlotSwap/SelectUmaAccount'
import ExpandableView from '../pages/ExpandableView'
import StrategyOnboarding from '../pages/StrategyOnboarding'
import AllSponsorsList from '../pages/PortfolioSpecification/AllSponsorsList'
import TradeAdhocMain from '../pages/Trade/TradeAdhoc/TradeAdhocMain'
import SponsorTable from '../pages/ACL'
import AggregateTreeView2 from '../pages/Aggregate/AggregateView/AggregateTreeView/AggregateView2'
import SelectAggregate from '../pages/Aggregate/AggregateView/AggregateList'
import AggregateOnboarding from '../pages/Aggregate/AggregateOnboarding'
import MandatoryTrades from '../pages/Trade/MandatoryTrades'
import ModelChanges from '../pages/ModelChanges'
import AccountMigration from '../pages/Migration'
import TaxlotSwap from '../pages/Aggregate/TaxlotSwap'
import UMAOnboarding from '../pages/Aggregate/UMAOnboarding'

const getHomePageBasedOnRole = (userGroup, curPath) => {
  switch (userGroup) {
    case 'pm':
      return curPath
    case 'adv-classic':
      return curPath
    case 'admin':
      return '/admin'
    default:
      return '/book-of-business'
  }
}

const AppRoutes = () => {
  const { user, curPath } = useAuth()
  const userGroup = localStorage.getItem('userGroup')

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Outlet />}>
            <Route
              index
              element={<Navigate to={getHomePageBasedOnRole((user?.userGroup || userGroup), curPath)} replace />}
            />
            <Route element={<ContentLayout />}>
              <Route
                path='book-of-business'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic']}>
                    <BookOfBusiness />
                  </PrivateRoute>
                }
              />
              <Route
                path='mfa-authentication'
                element={
                  <PrivateRoute>
                    <MfaQRCode />
                  </PrivateRoute>
                }
              />
              <Route
                path='trade/trade-approvals'
                element={
                  <PrivateRoute roles={['pm']}>
                    <TradeApprovalsMain />
                  </PrivateRoute>
                }
              />
              <Route
                path='trade/trade-blotter'
                element={
                  <PrivateRoute roles={['pm']}>
                    <TradeBlotterMain />
                  </PrivateRoute>
                }
              />
              <Route
                path='trade/trade-comparator'
                element={
                  <PrivateRoute roles={['pm']}>
                    <TradeComparatorMain />
                  </PrivateRoute>
                }
              />
              <Route
                path='trade/trade-history'
                element={
                  <PrivateRoute roles={['pm']}>
                    <TradeApprovedMain />
                  </PrivateRoute>
                }
              />
              <Route
                path='trade/trade-adhoc'
                element={
                  <PrivateRoute roles={['pm']}>
                    <TradeAdhocMain />
                  </PrivateRoute>
                }
              />
              <Route
                path='trade/mandatory-trades'
                element={
                  <PrivateRoute roles={['pm']}>
                    <MandatoryTrades />
                  </PrivateRoute>
                }
              />
              <Route
                path='/account-review/account-dashboard/:accountId'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <AccountDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-review/risk-dashboard/:accountId'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <RiskDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path='/account-review/request/:accountId'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <AccountRequest />
                  </PrivateRoute>
                }
              />
              <Route
                path='/account-review/personalization/:accountId'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <Personalization />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-onboarding'
                element={
                  <PrivateRoute roles={['adv-classic']}>
                    <AccountOnboarding />
                  </PrivateRoute>
                }
              />
              <Route
                path='/data-maintenance/data-maintenance-accounts'
                element={
                  <PrivateRoute roles={['pm']}>
                    <Index name='account' linkColumn='accountId' breadcrumb />
                  </PrivateRoute>
                }
              />
              <Route
                path='/data-maintenance/data-maintenance-request'
                element={
                  <PrivateRoute roles={['pm']}>
                    <Index name='request' breadcrumb={false} />
                  </PrivateRoute>
                }
              />
              <Route
                path='/data-maintenance/data-maintenance-strategy-allocation'
                element={
                  <PrivateRoute roles={['pm']}>
                    <Index name='strategyAllocation' linkColumn='strategyId' breadcrumb />
                  </PrivateRoute>
                }
              />
              <Route
                path='/data-maintenance/data-maintenance-master'
                element={
                  <PrivateRoute roles={['pm']}>
                    <Index name='master' breadcrumb={false} />
                  </PrivateRoute>
                }
              />
              <Route
                path='/data-maintenance/data-maintenance-strategy'
                element={
                  <PrivateRoute roles={['pm']}>
                    <Index name='strategy' linkColumn='strategyId' breadcrumb />
                  </PrivateRoute>
                }
              />
              <Route
                path='/data-maintenance/data-maintenance-optimization'
                element={
                  <PrivateRoute roles={['pm']}>
                    <Index name='optimization' breadcrumb={false} />
                  </PrivateRoute>
                }
              />
              <Route
                path='/data-maintenance/data-maintenance-sponsor'
                element={
                  <PrivateRoute roles={['pm']}>
                    <Index name='sponsor' breadcrumb={false} />
                  </PrivateRoute>
                }
              />
              <Route
                path='/data-maintenance/data-maintenance-report'
                element={
                  <PrivateRoute roles={['pm']}>
                    <Index name='report' breadcrumb={false} />
                  </PrivateRoute>
                }
              />
              <Route
                path='/data-maintenance/data-maintenance-instrument'
                element={
                  <PrivateRoute roles={['pm']}>
                    <Index name='instrument' breadcrumb={false} />
                  </PrivateRoute>
                }
              />
              <Route
                path='/data-maintenance/data-maintenance-trade'
                element={
                  <PrivateRoute roles={['pm']}>
                    <Index name='trade' breadcrumb={false} />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-review/account-dashboard'
                exact
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <ListOfaAllAccount title='account-dashboard' />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-review/risk-dashboard'
                exact
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <ListOfaAllAccount title='risk-dashboard' />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-review/request'
                exact
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <ListOfaAllAccount title='request' />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-review/personalization'
                exact
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <ListOfaAllAccount title='personalization' />
                  </PrivateRoute>
                }
              />
              <Route
                path='all-requests'
                element={
                  <PrivateRoute roles={['pm']}>
                    <AllRequestTabs />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-review/import-portfolio/:accountId'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <AccountPortfolioImport />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-review/import-portfolio'
                exact
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <ListOfaAllAccount title='import-portfolio' />
                  </PrivateRoute>
                }
              />
              <Route
                path='model/strategy-portfolio/:strategyId'
                element={
                  <PrivateRoute roles={['pm']}>
                    <StrategyImportPage />
                  </PrivateRoute>
                }
              />
              <Route
                path='model/strategy-portfolio'
                element={
                  <PrivateRoute roles={['pm']}>
                    <AllStrategyList resource='strategy-portfolio' />
                  </PrivateRoute>
                }
              />
              <Route
                path='model/port-spec'
                element={
                  <PrivateRoute roles={['pm']}>
                    <AllSponsorsList />
                  </PrivateRoute>
                }
              />
              <Route
                path='model/model-changes/:strategyId'
                element={
                  <PrivateRoute roles={['pm']}>
                    <ModelChanges />
                  </PrivateRoute>
                }
              />
              <Route
                path='model/model-changes'
                element={
                  <PrivateRoute roles={['pm']}>
                    <AllStrategyList resource='model-changes' />
                  </PrivateRoute>
                }
              />
              <Route
                path='model/strategy-onboarding'
                element={
                  <PrivateRoute roles={['pm']}>
                    <StrategyOnboarding />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-review/expanded-charts/:accountId'
                exact
                element={
                  <PrivateRoute roles={['pm']}>
                    <ExpandableView />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-review/reports/:accountId/:RequestId'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <ReportPage />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-review/reports/:accountId'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <ReportPage />
                  </PrivateRoute>
                }
              />
              <Route
                path='account-review/reports'
                exact
                element={
                  <PrivateRoute roles={['pm', 'adv-classic', 'spn-pm']}>
                    <ListOfaAllAccount title='reports' />
                  </PrivateRoute>
                }
              />
              <Route
                path='/pdf-customizer/*' element={
                  <PrivateRoute>
                    <PDFLayout />
                  </PrivateRoute>
                }
              />
              <Route
                path='aggregate/aggregate-view-group/:familyId'
                exact
                element={
                  <PrivateRoute roles={['pm']}>
                    <AggregateTreeView2 />
                  </PrivateRoute>
                }
              />
              <Route
                path='aggregate/entity-mapping'
                exact
                element={
                  <PrivateRoute roles={['pm']}>
                    <SelectAggregate title='entity-mapping' />
                  </PrivateRoute>
                }
              />
              <Route
                path='aggregate/entity-mapping/:familyId'
                exact
                element={
                  <PrivateRoute roles={['pm']}>
                    <AggregateTreeView />
                  </PrivateRoute>
                }
              />
              <Route
                path='aggregate/aggregate-dashboard/:aggGroupId'
                element={
                  <PrivateRoute roles={['pm']}>
                    <IPSAggregate />
                  </PrivateRoute>
                }
              />
              <Route
                path='aggregate/aggregate-dashboard'
                exact
                element={
                  <PrivateRoute roles={['pm']}>
                    <SelectFamilyAccount title='aggregate-dashboard' />
                  </PrivateRoute>
                }
              />
              <Route
                path='aggregate/group-onboarding'
                exact
                element={
                  <PrivateRoute roles={['pm', 'adv-classic']}>
                    <AggregateOnboarding />
                  </PrivateRoute>
                }
              />
              <Route
                path='aggregate/uma-onboarding'
                exact
                element={
                  <PrivateRoute roles={['pm']}>
                    <UMAOnboarding />
                  </PrivateRoute>
                }
              />
              <Route
                path='/account-migration'
                exact
                element={
                  <PrivateRoute roles={['pm']}>
                    <AccountMigration />
                  </PrivateRoute>
                }
              />
              <Route
                path='aggregate/taxlot-swapping'
                exact
                element={
                  <PrivateRoute roles={['pm']}>
                    <SelectUmaAccount title='taxlot-swapping' />
                  </PrivateRoute>
                }
              />
              <Route
                path='aggregate/taxlot-swapping/:familyId'
                exact
                element={
                  <PrivateRoute roles={['pm']}>
                    <TaxlotSwap title='taxlot-swapping' />
                  </PrivateRoute>
                }
              />
              <Route
                path='ips/monitoring'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic']}>
                    <IPSPage title='Monitoring' className='reportPage'>
                      <Monitoring />
                    </IPSPage>
                  </PrivateRoute>
                }
              />
              <Route
                path='ips/evidence'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic']}>
                    <IPSPage title='Evidence' className='reportPage'>
                      <Evidence />
                    </IPSPage>
                  </PrivateRoute>
                }
              />
              <Route
                path='ips/statement'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic']}>
                    <IPSPage title='Statement' className='reportPage'>
                      <Statement />
                    </IPSPage>
                  </PrivateRoute>
                }
              />
              <Route
                path='admin'
                element={
                  <PrivateRoute roles={['admin']}>
                    <SponsorTable />
                  </PrivateRoute>
                }
              />
              <Route path='*' element={<PageNotFound />} />
              <Route
                path='ips/policy'
                element={
                  <PrivateRoute roles={['pm', 'adv-classic']}>
                    <IPSPage title='Policy' className='PolicyPage'>
                      <PolicyPage />
                    </IPSPage>
                  </PrivateRoute>
                }
              />
              <Route
                path='/pdf-customizer/*'
                element={
                  <PrivateRoute>
                    <PDFLayout />
                  </PrivateRoute>
                }
              />
              <Route
                path='process-monitoring'
                element={
                  <PrivateRoute roles={['pm']}>
                    <ProcessMonitoring />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path='forgot-password'
              element={<UserEmailVerification />}
            />
            <Route path='login' element={<Login />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}
export default AppRoutes
