import { Box, Card, CardContent, Grid, IconButton, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector, DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin

// Extend dayjs with the utc plugin
dayjs.extend(utc)

const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const getPurchaseCost = (value) => {
  return value !== undefined && value !== null ? parseFloat((value || 0).toFixed(2)) : null
}

const taxlotLevelcolumns = [
  { field: 'instrName', headerName: 'Name', flex: 2 },
  { field: 'symbol', headerName: 'Symbol', flex: 1, valueGetter: (params) => params.row.instrId === '__USD' ? '__CASH' : params.row.instrId },
  { field: 'shares', headerName: 'Share', flex: 1, type: 'number', renderCell: (params) => params.value !== undefined && params.value !== null ? params.value : '' },
  {
    field: 'purchaseCost',
    headerName: 'Purchase Cost',
    flex: 1,
    type: 'number',
    valueGetter: (params) => (params.row.instrId === '__USD' || params.row.instrId === '__CASH')
      ? getPurchaseCost(params.row.shares)
      : getPurchaseCost(params.row.purchaseCost),
    renderCell: (params) => params.value !== null ? `${formateToUsdCurrency.format(params.value)}` : ''
  },
  { field: 'purchaseDate', headerName: 'Purchase Date', flex: 1, renderCell: (params) => params.row.purchaseDate ? params.row.purchaseDate.split('T')[0] : '' },
  { field: 'isoCountryName', headerName: 'Country Code', flex: 1 }
]

const companyLevelColumns = [
  { field: 'symbol', headerName: 'Symbol', flex: 1 },
  { field: 'shares', headerName: 'Share', flex: 1, type: 'number', renderCell: (params) => params.value !== undefined && params.value !== null ? params.value : '' },
  {
    field: 'purchaseCost',
    headerName: 'Purchase Cost',
    flex: 1,
    type: 'number',
    valueGetter: (params) => (params.row.instrId === '__USD' || params.row.instrId === '__CASH')
      ? getPurchaseCost(params.row.shares)
      : getPurchaseCost(params.row.purchaseCost),
    renderCell: (params) => params.value !== null ? `${formateToUsdCurrency.format(params.value)}` : ''
  },
  { field: 'purchaseDate', headerName: 'Purchase Date', flex: 1, renderCell: (params) => params.row.purchaseDate ? params.row.purchaseDate.split('T')[0] : '' },
  { field: 'isoCountryName', headerName: 'Country Code', flex: 1 }
]

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box>
      {filteredDescendantCount > 0
        ? (
          <>
            <IconButton
              size='small'
              onClick={handleClick}
              aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
            >
              <ExpandMoreIcon
                sx={{
                  transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest
                    })
                }}
                fontSize='inherit'
              />
            </IconButton>
            <span>
              {props?.row?.name}
            </span>
          </>
          )
        : (
          <span style={{ marginLeft: rowNode.depth * 27 }}>
            {props?.row?.name}
          </span>
          )}
    </Box>
  )
}

const RenderTableSkeleton = ({ headers }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((item, index) => {
            return (
              <TableCell key={index}>{item.headerName}</TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from({ length: 5 }).map((_, index) => (
          <TableRow key={index}>
            {Array.from({ length: headers.length }).map((_, index) => (
              <TableCell key={index}>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
              </TableCell>))}
          </TableRow>))}
      </TableBody>
    </Table>
  )
}

const CurrentImport = ({ taxlotData, portfolioLevel, companyWisePortfolio, isTaxlotLoading, noDataMsg }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            {isTaxlotLoading
              ? (
                <Box className='table-responsive-import'>
                  <RenderTableSkeleton headers={taxlotLevelcolumns} />
                </Box>
                )
              : taxlotData?.length > 0
                ? portfolioLevel === 'Company'
                  ? (
                    <DataGridPro
                      rows={companyWisePortfolio}
                      columns={companyLevelColumns}
                      getRowId={(row) => row.id}
                      disableRowSelectionOnClick
                      autoHeight
                      pagination
                      density='compact'
                      treeData
                      getTreeDataPath={(row) => row.hierarchy}
                      groupingColDef={{
                        headerName: 'Name',
                        flex: 2,
                        filterable: true,
                        sortable: true,
                        disableColumnMenu: false,
                        valueGetter: (params) => params?.row?.name,
                        renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
                      }}
                      initialState={{
                        ...companyWisePortfolio?.initialState,
                        pagination: { paginationModel: { pageSize: 15 } }
                      }}
                      pageSizeOptions={[15, 25, 50, 75, 100]}
                      sx={{
                        '& .MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        [`.${gridClasses.main}`]: {
                          height: 'calc(100vh - 48px - 15px - 20px - 56px - 48px - 54px - 16px - 24px - 39px - 53px)'
                        },
                        [`.${gridClasses.columnHeaders}`]: {
                          position: 'sticky',
                          top: 0,
                          zIndex: 1
                        },
                        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                          color: '#74788d',
                          fontWeight: 600
                        },
                        [`.${gridClasses.virtualScroller}`]: {
                          overflowY: 'auto !important',
                          scrollbarGutter: 'stable',
                          scrollbarWidth: 'none'
                        }
                      }}
                    />
                    )
                  : (
                    <DataGridPro
                      rows={taxlotData || []}
                      columns={taxlotLevelcolumns}
                      getRowId={(row) => row.id}
                      disableRowSelectionOnClick
                      autoHeight
                      pagination
                      getTreeDataPath={(row) => [row.id]}
                      density='compact'
                      initialState={{
                        ...taxlotData?.initialState,
                        pagination: { paginationModel: { pageSize: 15 } }
                      }}
                      pageSizeOptions={[15, 25, 50, 75, 100]}
                      sx={{
                        '& .MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        [`.${gridClasses.main}`]: {
                          height: 'calc(100vh - 48px - 15px - 20px - 56px - 48px - 54px - 16px - 24px - 39px - 53px)'
                        },
                        [`.${gridClasses.columnHeaders}`]: {
                          position: 'sticky',
                          top: 0,
                          zIndex: 1
                        },
                        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                          color: '#74788d',
                          fontWeight: 600
                        },
                        [`.${gridClasses.virtualScroller}`]: {
                          overflowY: 'auto !important',
                          scrollbarGutter: 'stable',
                          scrollbarWidth: 'none'
                        }
                      }}
                    />
                    )
                : (
                  <Stack style={{ minHeight: '30px' }}>
                    {noDataMsg || 'No rows'}
                  </Stack>
                  )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default CurrentImport
