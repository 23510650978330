import { Box, Divider, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'

const currentUMACols = [
  {
    field: 'accountName',
    headerName: 'Account Name',
    flex: 1
  },
  {
    field: 'weight',
    headerName: 'Weight',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.row?.weight !== undefined && params?.row?.weight !== null ? params?.row?.weight?.toFixed(2) : 'NA'
  },
  {
    field: 'totalMarketValue',
    headerName: 'Market Value',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      params?.row?.totalMarketValue !== undefined
        ? params?.row?.totalMarketValue !== null
          ? params?.row?.totalMarketValue < 0
            ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
            : '$' + Number(params?.value)?.toLocaleString()
          : 'NA'
        : ''
    )
  },
  {
    field: 'totalInitUrgl',
    headerName: 'Unrealized Gain/Loss',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      params?.row?.totalInitUrgl !== undefined
        ? params?.row?.totalInitUrgl !== null
          ? params?.row?.totalInitUrgl < 0
            ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
            : '$' + Number(params?.value)?.toLocaleString()
          : 'NA'
        : ''
    )
  }
]

const scenarioCols = [
  {
    field: 'accountName',
    headerName: 'Account Name',
    flex: 1
  },
  {
    field: 'weight',
    headerName: 'Weight',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => typeof params?.row?.weight !== 'undefined' && params?.row?.weight !== null ? params?.row?.weight?.toFixed(2) : 'NA'
  },
  {
    field: 'totalMarketValue',
    headerName: 'Market Value',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      params?.row?.totalMarketValue !== undefined
        ? params?.row?.totalMarketValue !== null
          ? params?.row?.totalMarketValue < 0
            ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
            : '$' + Number(params?.value)?.toLocaleString()
          : 'NA'
        : ''
    )
  },
  {
    field: 'totalInitUrgl',
    headerName: 'Unrealized Gain/Loss',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      params?.row?.totalInitUrgl !== undefined
        ? params?.row?.totalInitUrgl !== null
          ? params?.row?.totalInitUrgl < 0
            ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
            : '$' + Number(params?.value)?.toLocaleString()
          : 'NA'
        : ''
    )
  },
  {
    field: 'totalRgl',
    headerName: 'Realized Gain/Loss',
    type: 'number',
    align: 'right',
    flex: 1,
    valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
    renderCell: (params) => (
      params?.row?.totalRgl !== undefined
        ? params?.row?.totalRgl !== null
          ? params?.row?.totalRgl < 0
            ? '-$' + Math.abs(Number(params?.row?.totalRgl))?.toLocaleString(undefined, { maximumFractionDigits: 2 })
            : '$' + Number(params?.row?.totalRgl)?.toLocaleString(undefined, { maximumFractionDigits: 2 })
          : 'NA'
        : ''
    )
  }
]

const PortfolioTable = ({ data, loading = false, umaType }) => {
  const originalUMAData = (data?.originalUMA?.allocationData || []).map(data => ({ ...data, id: randomId() }))
  const currentUMAData = (umaType === 'SCENARIO_TRANSITION' ? data?.targetUMA?.allocationData || [] : originalUMAData || []).map(data => ({ ...data, id: randomId() }))

  const currentAggObj = currentUMAData?.reduce((sumObj, obj) => {
    return {
      weight: sumObj?.weight + obj?.weight,
      totalMarketValue: sumObj?.totalMarketValue + obj?.totalMarketValue,
      totalRgl: sumObj?.totalRgl + obj?.totalRgl,
      totalInitUrgl: sumObj?.totalInitUrgl + obj?.totalInitUrgl
    }
  }, { totalMarketValue: 0, weight: 0, totalRgl: 0, totalInitUrgl: 0 })

  const originalAggObj = umaType === 'SCENARIO_TRANSITION' ? originalUMAData?.reduce((sumObj, obj) => {
    return {
      weight: sumObj?.weight + obj?.weight,
      totalMarketValue: sumObj?.totalMarketValue + obj?.totalMarketValue,
      totalInitUrgl: sumObj?.totalInitUrgl + obj?.totalInitUrgl,
      totalRgl: sumObj?.totalRgl + obj?.totalRgl
    }
  }, { totalMarketValue: 0, weight: 0, totalInitUrgl: 0, totalRgl: 0 }) : {}

  const totalRowCurrentUMA = {
    id: randomId(),
    accountName: 'Total',
    weight: currentAggObj?.weight || 0,
    totalMarketValue: currentAggObj?.totalMarketValue || 0,
    totalInitUrgl: currentAggObj?.totalInitUrgl || 0,
    ...(umaType === 'SCENARIO_TRANSITION' ? { totalRgl: currentAggObj?.totalRgl || 0 } : null)
  }

  const totalRowOriginalUMA = {
    id: randomId(),
    accountName: 'Total',
    weight: originalAggObj?.weight || 0,
    totalMarketValue: originalAggObj?.totalMarketValue || 0,
    totalRgl: originalAggObj?.totalRgl || 0,
    totalInitUrgl: originalAggObj?.totalInitUrgl || 0
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#74788D', mt: '10px' }}>Current Portfolio Allocation</Typography>
        {
          loading
            ? (
              <TableContainer mt={10}>
                <Table className='risk-page-table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Account Name</TableCell>
                      {currentUMACols.map((item, index) => {
                        return (
                          <TableCell key={index}>{item.headerName}</TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 5 }).map((_, i) => (
                      <TableRow key={i}>
                        {
                          Array.from({ length: currentUMACols.length }).map((_, j) => (
                            <TableCell key={j}>
                              <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                            </TableCell>
                          ))
                        }
                        <TableCell>
                          <Skeleton variant='text' />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
            : (
              <DataGridPro
                autoHeight
                density='compact'
                rows={currentUMAData}
                pinnedRows={{ bottom: currentUMAData?.length ? [totalRowCurrentUMA] : [] }}
                columns={currentUMACols}
                getRowId={(row) => row?.id}
                disableRowSelectionOnClick
                hideFooter
                localeText={{ noRowsLabel: 'No Portfolio Found' }}
                sx={(theme) => ({
                  [`.${gridClasses.pinnedRows}`]: {
                    boxShadow: 'none',
                    borderBlock: '1px solid black'
                  },
                  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                    color: '#74788d',
                    fontWeight: 600
                  }
                })}
              />
            )
        }
      </Box>
      {
        umaType === 'SCENARIO_TRANSITION' ? (
          <>
            <Divider />
            <Box sx={{ mt: '20px', width: '100%' }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#74788D', my: '10px' }}>Original UMA Allocation</Typography>
              {
                loading
                  ? (
                    <TableContainer mt={10}>
                      <Table className='risk-page-table'>
                        <TableHead>
                          <TableRow>
                            <TableCell>Account Name</TableCell>
                            {scenarioCols.map((item, index) => {
                              return (
                                <TableCell key={index}>{item.headerName}</TableCell>
                              )
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.from({ length: 5 }).map((_, i) => (
                            <TableRow key={i}>
                              {
                                Array.from({ length: scenarioCols.length }).map((_, j) => (
                                  <TableCell key={j}>
                                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                                  </TableCell>
                                ))
                              }
                              <TableCell><Skeleton variant='text' /></TableCell>
                            </TableRow>))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )
                  : (
                    <DataGridPro
                      density='compact'
                      autoHeight
                      rows={originalUMAData}
                      columns={scenarioCols}
                      getRowId={(row) => row?.id}
                      pinnedRows={{ bottom: originalUMAData?.length ? [totalRowOriginalUMA] : [] }}
                      disableRowSelectionOnClick
                      localeText={{ noRowsLabel: 'No Portfolio Found' }}
                      hideFooter
                      sx={(theme) => ({
                        [`.${gridClasses.pinnedRows}`]: {
                          boxShadow: 'none',
                          borderBlock: '1px solid black'
                        },
                        [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                          color: '#74788d',
                          fontWeight: 600
                        }
                      })}
                    />
                  )
              }
            </Box>
          </>
        ) : ''
      }
    </>
  )
}

export default PortfolioTable
